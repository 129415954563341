.eu-edit-leg-pointer-area {
  cursor:pointer;
  transition: background-color 0.5s linear;
}

.eu-edit-leg-pointer-area:hover {
    background-color: #7a86a0;
}

.eu-edit-leg-ymlist-area {
  width: 70px;
  height: 280px;
  position: absolute;
  top: 34px;
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000000;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
  display: none;
}

.eu-edit-leg-ymlist-area-up {
  width: 70px;
  height: 280px;
  position: absolute;
  top: -282px;
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000000;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
  display: none;
}

.eu-edit-leg-ymlist {
  width: 70px;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: #303548;   
}

.eu-edit-leg-ym {
  width: 100%;
  height: 40px;
  display: flex;
  cursor: pointer;
}
  
.eu-edit-leg-ym > div {
  margin: auto auto auto 10px;
  color: #FFFFFF;
  font-family: Meiryo;
  font-size: 14px;
}
  
.eu-edit-leg-ym:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.04);
}

.eu-edit-leg-ym:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.eu-edit-leg-ymlist-area:hover
, .eu-edit-leg-ymlist-area-up:hover {
  display: block;
}

.eu-edit-leg-ymlist-area-none {
  display: none;
}

.eu-edit-leg-time > input[type="tel"]:focus  + .eu-edit-leg-ymlist-area
, .eu-edit-leg-time > input[type="tel"]:focus  + .eu-edit-leg-ymlist-area-up {
  display: block;
}

.eu-edit-leg-ymlist-area::-webkit-scrollbar
, .eu-edit-leg-ymlist-area-up::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.eu-edit-leg-ymlist-area::-webkit-scrollbar-track
, .eu-edit-leg-ymlist-area-up::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
.eu-edit-leg-ymlist-area::-webkit-scrollbar-thumb
, .eu-edit-leg-ymlist-area-up::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}
.eu-edit-leg-ymlist-area::-webkit-scrollbar-thumb:hover
, eu-edit-leg-ymlist-area-up::-webkit-scrollbar-thumb:hover {
  background-color: #555,
}
.eu-edit-leg-ymlist-area::-webkit-scrollbar-corner
, .eu-edit-leg-ymlist-area-up::-webkit-scrollbar-corner {
  display: none;
}

.eu-edit-leg-time-hhmm {
  width: 60px;
  font-family: 'Roboto';
  font-weight: 400;
  border: 0px grey solid;
  color: white;
  font-Size: 13px;
  height: 35px;
  background: rgba(255, 255, 255, 0.15);
  text-align: center;
  border-bottom: 2px solid #A4A7B1;
  outline: none;

  padding-right: 20px;
 
}
.eu-edit-leg-time-hhmm:hover {
  background-color: #7a86a0;
  border: 0px #CBCBCB solid;
  border-bottom: 1px solid white;
}
