
/* Simulation */
.eu-simulation-list-wrapper {

    width: 97%;
    height: calc(100%);
    margin: 0 auto;
    white-space: nowrap;
    overflow-y: hidden;

    .simulation-list {
        /* font-size: 14px; */
        width: 100%;
        height: 63%;
        /* table-layout: fixed; */
        display: block;

        thead tr {
            width: 100%;
            /* height: 100%; */
            align-items: center;
            display: table;
            border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
        }
        tbody tr {
            width: 100%;
            height: 50px;
            align-items: center;
            display: table;
        }

        thead {
            display: table;
            width: 100%;

            th {
                /* border: 1px #CBCBCB solid; */
                font-family: Roboto, "Helvetica Neue", sans-serif;
                font-weight: 700;
                position: sticky;

                span {
                    font-size: 14px;
                    color: rgb(255, 255, 255, 0.5);
                }
            }
            .leg-no {
                width: 6%;
                text-align: center;
            }
            .operator {
                width: 5%;
                text-align: left;
            }
            .departure {
                width: 11%;
                text-align: left;
            }
            .padding1 {
                width: 2%;
                text-align: left;
            }
            .arrival {
                width: 11%;
                text-align: left;
            }
            .total-time {
                width: 7%;
                text-align: right;
            }
            .distance {
                width: 8%;
                text-align: right;
            }
            .eu-rate {
                width: 5%;
                text-align: right;
            }
            .padding2 {
                width: 2%;
                text-align: left;
            }
            .fuel {
                width: 10%;
                text-align: left;
            }
            .displacement {
                width: 7%;
                text-align: left;
            }
            .log-speed {
                width: 9%;
                text-align: right;
            }
            .foc {
                width: 6%;
                text-align: right;
            }
            .eua {
                width: 5%;
                text-align: right;
            }
            .copliance-balance {
                width: 6%;
                text-align: right;
                padding-right: 20px;
            }
        }

        tbody {
            display: block;
            overflow-y: auto;
            height: 90%;
            width: 100%;

            /* background-color: antiquewhite; */

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.5);
            }

            &::-webkit-scrollbar-thumb {
                background-color: #fff;
                border-radius: 10px;
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-corner {
                display: none;
            }

            tr {

                width: 100%;

                &:nth-of-type(odd) {
                    background-color: rgba(255, 255, 255, 0.05);
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.15);
                }

                td {
                    padding-bottom: 0px;
                    /* border: 1px #CBCBCB solid; */
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 15px;
                    word-wrap: 'break-word';
                    vertical-align: middle;
                    white-space: nowrap;
                    height: 50px;
                    max-height: 50px;

                    span {
                        font-size: 15px;
                        color: rgb(255, 255, 255, 1);
                    }
                }
                .leg-no {
                    width: 6%;
                    text-align: center;
                }
                .operator {
                    width: 5%;
                    text-align: left;
                    padding-left: 5px;
                }
                .departure {
                    width: 11%;
                    text-align: left;
                }
                .padding1 {
                    width: 2%;
                    text-align: left;
                }
                .arrival {
                    width: 11%;
                    text-align: left;
                }
                .total-time {
                    width: 7%;
                    text-align: right;
                }
                .distance {
                    width: 8%;
                    text-align: right;
                }
                .eu-rate {
                    width: 5%;
                    text-align: right;
                }
                .padding2 {
                    width: 2%;
                    text-align: left;
                }
                .fuel {
                    width: 10%;
                    text-align: left;
                }
                .displacement {
                    width: 7%;
                    text-align: left;
                }
                .log-speed {
                    width: 9%;
                    text-align: right;
                }
                .foc {
                    width: 6%;
                    text-align: right;
                }
                .eua {
                    width: 5%;
                    text-align: right;
                }
                .copliance-balance {
                    width: 6%;
                    text-align: right;
                    padding-right: 20px;
                }
            }
        }    
    }
}

.simulation-result-titole {
    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 700;
    /* height: 13px; */
}

.simulation-result-param {
    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-family: "Roboto";
}

.simulation-result-unit {
    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-family: "Roboto Condensed";
    margin-left: 6px;
}

.simulation-speed-plan-header {
    /* border:1px solid #5A5A5A; */
    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700;
    line-height: 24px;
    word-wrap: 'break-word'
}

.simulation-speed-plan-data {
    /* border:1px solid #5A5A5A; */
    color: rgba(255, 255, 255, 0.70);
    font-size: 15px;
    font-family: 'Roboto';
    line-height: 24px;
    word-wrap: 'break-word';
    text-align: center;
}

