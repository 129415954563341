#MasterInput-fixedTableArea {
    width: 1700px;
    height: 650px;
}

.fixedTable {
    border-collapse: collapse;
}

#MasterInput-topRightArea {
    width: 1700px;
    float: left;
    overflow: hidden;

    td {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        height: 30px;
        font-size: 13px;
        color: rgb(255, 255, 255, 0.5);

    }
}

#MasterInput-bottomRightArea {
    width: 1700px;
    height: 650px;
    /* overflow: scroll; */
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.5);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-corner {
        display: none;
    }

    tr {

        width: 100%;

        &:nth-of-type(odd) {
            background-color: rgba(255, 255, 255, 0.05);
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.15);
        }

        .cell {
            text-align: center;
            min-width: 1690px;
            max-width: 1690px;
            overflow: hidden;
            white-space: nowrap;
            height: 60px;
            font-size: 13px;
            color: rgb(255, 255, 255, 0.5);

        }

    }
}


#MasterInput-fixedTableArea-sp {
    width: 1700px;
    height: 600px;
}

.fixedTable {
    border-collapse: collapse;
}

#MasterInput-topRightArea-sp {
    width: 1700px;
    float: left;
    overflow: hidden;

    td {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        height: 30px;
        font-size: 13px;
        color: rgb(255, 255, 255, 0.5);

    }
}

#MasterInput-bottomRightArea-sp {
    width: 1700px;
    height: 600px;
    /* overflow: scroll; */
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.5);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-corner {
        display: none;
    }

    tr {

        width: 100%;
        background-color: rgba(255, 255, 255, 0.05);

        /* &:nth-of-type(odd) {
            background-color: rgba(255, 255, 255, 0.05);
        } */

        &:hover {
            background-color: rgba(255, 255, 255, 0.15);
        }

        .cell {
            text-align: center;
            min-width: 1690px;
            max-width: 1690px;
            overflow: hidden;
            white-space: nowrap;
            height: 60px;
            font-size: 13px;
            color: rgb(255, 255, 255, 0.5);

        }

    }
}