/* 
* FleetOverview専用CSS。
* 他ページへの影響を避けるためクラス名プリフィクスとして「flov」を付ける
*/
.map-container {
  width: 100%; /* メイン部分全体の幅を占有 */
  height: 100%; /* 高さを画面全体に合わせる */
  position: relative; /* 絶対配置を避ける */
  z-index: 1;
}

.flov-vessel-list-wrapper {
  height: calc(100%);

  .flov-vessel-list-area-wrapper {
      width: 100%;
      height: calc(100%);
      margin: 0 auto;
      white-space: nowrap;
      overflow-y: hidden;

      .flov-vessel-list-table {
          font-size: 13px;
          width: 360px;
          height: 100%;
          margin: 0 auto;
          /* border-collapse: collapse; */
          table-layout: auto;
          display: block;

          thead tr {
              width: 100%;
              height: 100%;
              align-items: center;
              display: table;
              border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
          }
          tbody tr {
              width: 100%;
              height: 50px;
              align-items: center;
              display: table;
          }

          thead {
              display: table;
              width: 100%;

              th {
                  /* border: 1px #CBCBCB solid; */
                  font-family: Roboto, "Helvetica Neue", sans-serif;
                  font-weight: 500;
                  position: sticky;
                  /* vertical-align: middle; */

                  span {
                      font-size: 14px;
                      color: rgb(255, 255, 255, 0.5);
                  }
              }

              .favorite {
                  width: 3%;
                  text-align: left;
              }

              .alarm {
                  width: 1.5%;
                  text-align: left;
                  padding-left: 10px;
              }

              .vesselName {
                  width: 16.5%;
                  text-align: left;
                  /* background-color: blueviolet; */
              }

          }

          tbody {
              display: block;
              overflow-y: auto;
              height: 90%;
              width: 100%;

              &::-webkit-scrollbar-track {
                  border-radius: 10px;
                  background: rgba(255, 255, 255, 0.5);
              }

              &::-webkit-scrollbar-thumb {
                  background-color: #fff;
                  border-radius: 10px;
              }

              &::-webkit-scrollbar {
                  width: 8px;
                  height: 8px;
              }

              &::-webkit-scrollbar-corner {
                  display: none;
              }

              tr {

                  width: 100%;

                  &:nth-of-type(odd) {
                      background-color: rgba(255, 255, 255, 0.05);
                  }

                  &:hover {
                      background-color: rgba(255, 255, 255, 0.15);
                  }

                  td {
                      padding-bottom: 0px;
                      /* border: 1px #CBCBCB solid; */
                      font-family: Roboto;
                      font-weight: 400;
                      font-size: 15px;
                      word-wrap: 'break-word';
                      vertical-align: middle;
                      white-space: nowrap;
                      height: 50px;
                      max-height: 50px;

                      span {
                          font-size: 13px;
                          color: rgb(255, 255, 255, 1);
                      }
                  }


                  .favorite {
                      width: 3%;
                      text-align: center;
                  }

                  .alarm {
                      width: 1.5%;
                      text-align: left;
                      padding-left: 10px;
                  }

                  .vesselName {
                      width: 16.5%;
                      text-align: left;
                      padding-left: 20px;
                  }
              }
          }
      }
  }
}

/* 並び替え */
.flov-common-sort-container {
  display: flex; /* display: inline-block; から変更 */
  align-items: center; /* 縦方向の中央揃え */
  padding-left: 4px;
  height: 100%; /* 親要素の高さに合わせる */
}
.flov-common-sort-icon {
  display: flex; /* フレックスボックスでアイコンを中央揃え */
  flex-direction: column; /* 縦方向に並べる */
  justify-content: center; /* 縦方向の中央寄せ */
  height: 100%;
  margin-top: -5px;
}

.flov-common-sort-icon-ascending,
.flov-common-sort-icon-descending {
  width: 0;
  height: 0;
  margin-left: 2px;
  border-style: solid;
  cursor: pointer;
}

.flov-common-sort-icon-ascending {
  border-width: 0 5px 6px 5px;
  border-color: transparent transparent rgba(255, 255, 255, 0.5) transparent;
  margin-bottom: 0px; /* アイコン間の余白を追加 */
}

.flov-common-sort-icon-descending {
  border-width: 6px 5px 0 5px;
  border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
}

/* アラーム赤のぼかし */
.flov-alarm-blur {
  box-shadow: 2px 2px 25px red;
}

.flov-vessel-list-header-left{
  display: flex;
}
.flov-vessel-list-header-left > div:nth-child(1) {
  margin-top: 0px;
}

/*----------------------------------------
    船舶情報モーダル
------------------------------------------*/
/* モーダル全体 */
.ship-info-modal {
  position: absolute;
  top: 73px;
  right: 170px;
  width: 697px;
  height: 469px;
  padding: 20px;
  z-index: 10;
  transition: opacity 1.5s ease, transform 1.5s ease;
  border-radius: 20px;
}
.ship-info-modal::before{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(36,46,57);
  border: 2px solid rgb(68, 68, 68);
  border-radius: 10px;
}
.ship-info-modal.visible {
  opacity: 1;
}
.ship-info-modal.hidden {
  opacity: 0;
  pointer-events: none;
}

/* モーダルヘッダー表示エリア */
.modal-header-area {
  height: 35px;
  display: flex;
  /* background-color: brown; */
}

/* 船名 */
.modal-vesselName {
  margin-left: 25px;
  margin-top: 5px;
  font-size: 18px;
  color: rgba(255, 255, 255, 1.0);
}

/* グロストン・船種 */
.modal-grosstongue-vesselType {
  margin-left: 17.5px;
  margin-top: 11px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
}

/* 最終更新日時 */
.modal-lastUpdate-area {
  display: flex;
}

.modal-lastUpdate-label{
  margin-left: 30px;
  margin-top: 0px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
}

.modal-lastUpdate-value{
  margin-left: 10px;
  margin-top: 0px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1.0);
}

/* モーダル上部（Beaufort・航海予定表示エリア） */
.modal-top-area {
  height: 100px;
  width: 590px;
  margin-top: 10px;
  margin-left: 40px;
  border-radius: 5px;
  background-color: rgb(44, 54, 70);
  display: flex;
}

/* Beaufort */
.modal-beaufort-area{
  /* margin-left: 20px; */
  margin-top: 0px;
  width: auto;
  /* background-color: rgb(99, 120, 153); */
}

/* 航海予定 */
.modal-port-area {
  margin-left: 30px;
  margin-top: 0px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  /* background-color: rgb(99, 120, 153); */
  flex-grow: 1;
  padding: 10px;
}

.port-svg-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.port-name-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #fff;
  font-size: 14;
  font-family: 'Roboto';
}

.port-time-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-family: 'Roboto';
}

.port-time-label {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 5px;
}

/* モーダル中部（MEグラフ・FOC・CP表示エリア） */
.modal-middle-area {
  height: 120px;
  width: 590px;
  margin-top: 10px;
  margin-left: 40px;
  border-radius: 5px;
  /* background-color: rgb(44, 54, 70); */
  display: flex;
}

/* 主機グラフ表示エリア*/
.me-graph-area{
  height: auto;
  width: auto;
  display: flex;
  text-align: center;
  /* background-color: rgb(0, 66, 22); */
}
.me-logSpeed-chart-container {
  margin-top: 15px;
  margin-left: 10px;
}
.me-meRPM-chart-container {
  margin-top: 15px;
  margin-left: 15px;
}
.me-meLoad-chart-container {
  margin-top: 15px;
  margin-left: 15px;
}
.modal-separate-line-arer{
  height: auto;
  width: auto;
  /* background-color: aqua; */
}
.modal-separate-line{
  margin-top: 50px;
  margin-left: 50px;
}
.modal-separate-line-arer2{
  height: auto;
  width: 20px;
  /* background-color: aqua; */
}
.modal-separate-line2{
  margin-top: 40px;
  margin-left: 20px;
}
.modal-separate-line-arer3{
  height: auto;
  width: 60px;
  /* background-color: aqua; */
}
.modal-separate-line3{
  margin-top: 40px;
  margin-left: 60px;
}

/* FOC,CPAlarm表示エリア*/
.modal-foc-cp-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding-top: 45px;
  padding-left: 42px;
  /* background-color: rgb(28, 41, 85); */
  position: relative;
}

.modal-foc-area,
.modal-cp-area {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 20px;
  padding-right: 25px;
  position: relative;
}

.modal-foc-label,
.modal-cp-label {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  white-space: nowrap;
  flex: 1;
}

.modal-foc-value,
.modal-cp-value {
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: right;
  min-width: 50px;
}

.modal-foc-unit,
.modal-cp-unit {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Roboto Condensed';
  min-width: 50px;
  text-align: left;
  margin-left: 8px; /* ここで値と単位の間にスペースを追加 */
}

/* アラームアイコンの位置調整（単位に少し寄せる） */
.modal-cp-icon {
  position: absolute;
  right: 0px;
}

/* FOC と CP Curve Alarm の間隔を広げる */
.modal-cp-area {
  margin-top: 15px; /* さらに間隔を広げる */
}


/* モーダル下部（Year to Date,End of Year表示エリア） */
.modal-bottom-area {
  height: 100px;
  width: 590px;
  margin-top: 10px;
  margin-left: 40px;
  border-radius: 5px;
  /* background-color: rgb(44, 54, 70); */
  display: flex;
}
/* CII、EUA、CBのエリア全体を統一 */
.modal-cii-area,
.modal-eua-area,
.modal-complianceBalance-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 180px; /* 間隔を調整 */
  padding-left: 15px;
  color: rgba(255, 255, 255, 0.5);
}

/* タイトルの配置を左寄せ */
.modal-cii-title,
.modal-eua-title,
.modal-complianceBalance-title {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: -5px; /* 少し左へ移動 */
}

/* YTDとEOYの間隔を広げる */
.modal-cii-area > div,
.modal-eua-area > div,
.modal-complianceBalance-area > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px; /* 間隔を追加 */
}

/* YTDとEOYのラベルを少し右へ */
.modal-yod-label,
.modal-eua-yod-label,
.modal-complianceBalance-yod-label,
.modal-eoy-label,
.modal-eua-eoy-label,
.modal-complianceBalance-eoy-label {
  font-size: 13px;
  white-space: nowrap;
  margin-left: 10px; /* 少し右へ */
}

/* 値のスタイル */
.modal-yod-value,
.modal-eua-yod-value,
.modal-complianceBalance-yod-value,
.modal-eoy-value,
.modal-eua-eoy-value,
.modal-complianceBalance-eoy-value {
  font-size: 14px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  min-width: 30px;
  text-align: center;
}

/* CIIアイコンのサイズ調整 */
.modal-cii-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px; /* 余白調整 */
}

/* CIIアイコンの背景 */
.modal-cii-icon div {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  font-size: 14px;
}



/*----------------------------------------
    凡例
------------------------------------------*/
/* 凡例コンテナ */
.map-legend {
  position: absolute;
  bottom: 30px;
  left: 40px;
  background: #282F35;  /* 左のリストと同じ背景色 */
  padding: 5px 10px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  font-family: 'Roboto Condensed';
  display: flex;
  flex-direction: column;
  /* gap: 1px; */
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1000; /* 地図より前面に表示 */
}

/* 各凡例アイテム */
.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* 凡例アイコン */
.legend-icon {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

/* 各アラーム状態のアイコンカラー */
.legend-icon.warning {
  background: radial-gradient(rgba(253, 197, 197), rgba(251, 52, 52));
  border: 1px solid rgba(251, 52, 52);
}

.legend-icon.attention {
  background: radial-gradient(white, rgb(227, 227, 15));
  border: 1px solid rgb(227, 227, 15);
}

.legend-icon.good {
  background: radial-gradient(rgb(185, 203, 248), rgb(13, 74, 231));
  border: 1px solid rgb(13, 74, 231);
}

/*----------------------------------------
    マーカー
------------------------------------------*/
@keyframes warningGlow {
  0% {
    box-shadow: 0 0 10px rgba(251, 52, 52, 1), 0 0 20px rgba(251, 52, 52, 1);
  }
  50% {
    box-shadow: 0 0 15px rgba(251, 52, 52, 1), 0 0 25px rgba(251, 52, 52, 1);
  }
  100% {
    box-shadow: 0 0 10px rgba(251, 52, 52, 1), 0 0 20px rgba(251, 52, 52, 1);
  }
}
