#DataViewer-fixedTableArea {
    margin-left: 8px;
    width: 99%;
    height: calc(100vh - 200px);
}

.fixedTable {
    border-collapse: collapse;
}

.fixedTable td.dummyColumn {
    width: 17px;
    border: 0px;
}

.fixedTable td.dummyRow {
    height: 20px;
    border: 0px;
    background-color: transparent;
}

#DataViewer-topLeftArea {
    width: 290px;
    height: 30px;
    float: left;
    border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
}

#DataViewer-topRightArea {
    width: calc(100% - 290px);
    height: 30px;
    float: left;
    overflow: hidden;
    border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
}


#DataViewer-bottomLeftArea {
    width: 290px;
    height: 95%;
    float: left;
    overflow: hidden;

    tr {

        width: 100%;

        &:nth-of-type(odd) {
            background-color: rgba(255, 255, 255, 0.05);
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.15);
        }

        td {
            text-align: center;
            min-width: 150px;
            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            height: 30px;
            font-size: 13px;
            color: rgb(255, 255, 255, 0.8);

        }

        .dummyRow {
            height: 8px;
            border: 8px;
            background-color: transparent;
        }
    }
}

#DataViewer-bottomRightArea {
    width: calc(100% - 290px);
    height: 95%;
    overflow: auto;

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.5);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-corner {
        display: none;
    }

    tr {

        width: 100%;

        &:nth-of-type(odd) {
            background-color: rgba(255, 255, 255, 0.05);
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.15);
        }

        td {
            text-align: center;
            min-width: 150px;
            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            height: 30px;
            font-size: 13px;
            color: rgb(255, 255, 255, 0.8);

        }

        .alerm {
            text-align: center;
            min-width: 150px;
            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            height: 30px;
            font-size: 13px;
            color: rgb(255, 255, 255, 0.5);
            background-color: rgb(239, 83, 80, 0.4);
        }
    }
}

.download-csv {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-bottom: 4px;
    transition: filter 0.3s ease;
}

button:hover .download-csv {
    filter: brightness(0);
}