/* PopupMenu.scss */
.square {
    position: relative;
    margin:0 -12px 0 auto;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid white;

    .box-visible {
        visibility: visible;
    }

    .box-hidden {
        visibility: hidden;
    }
}