.vessel-list-wrapper {
    height: calc(100%);

    .vessel-list-area-wrapper {
        width: 100%;
        height: calc(100%);
        margin: 0 auto;
        white-space: nowrap;
        overflow-y: hidden;

        .vessel-list-table {
            font-size: 13px;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            /* border-collapse: collapse; */
            table-layout: fixed;
            display: block;

            thead tr {
                width: 100%;
                height: 100%;
                align-items: center;
                display: table;
                border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
            }
            tbody tr {
                width: 100%;
                height: 50px;
                align-items: center;
                display: table;
            }

            thead {
                display: table;
                width: 100%;

                th {
                    /* border: 1px #CBCBCB solid; */
                    font-family: Roboto, "Helvetica Neue", sans-serif;
                    font-weight: bold;
                    position: sticky;

                    span {
                        font-size: 14px;
                        color: rgb(255, 255, 255, 0.5);
                    }
                }

                .favorite {
                    width: 3%;
                    text-align: left;
                }

                .alarm {
                    width: 1.5%;
                    text-align: left;
                }

                .vesselName {
                    width: 16.5%;
                    text-align: left;
                }

                .latestUpdate {
                    width: 7.75%;
                    text-align: left;
                }

                .padding1 {
                    width: 1%;
                }

                .CPCurve {
                    width: 10%;
                    /* padding-left: 12px; */
                    text-align: left;
                }

                .padding2 {
                    width: 3%;
                }

                .ThisYear {
                    width: 7%;
                    text-align: center;
                }

                .EndofYear {
                    width: 7.5%;
                    text-align: center;
                }

                .LastYear {
                    width: 6%;
                    text-align: center;

                }

                /* .padding3 {
                    width: 0.75%;
                } */

                .eua-year-to-date {
                    width: 7%;
                    text-align: right;

                }

                .eua-end-of-year {
                    width: 7%;
                    text-align: right;

                }

                .padding4 {
                    width: 2%;
                }

                .cb-year-to-date {
                    width: 7%;
                    text-align: right;

                }

                .cb-end-of-year {
                    width: 7%;
                    text-align: right;

                }

                .padding5 {
                    width: 5.75%;
                }

            }

            tbody {
                display: block;
                overflow-y: auto;
                height: 90%;
                width: 100%;

                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background: rgba(255, 255, 255, 0.5);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #fff;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }

                &::-webkit-scrollbar-corner {
                    display: none;
                }

                tr {

                    width: 100%;

                    &:nth-of-type(odd) {
                        background-color: rgba(255, 255, 255, 0.05);
                    }

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.15);
                    }

                    td {
                        padding-bottom: 0px;
                        /* border: 1px #CBCBCB solid; */
                        font-family: Roboto;
                        font-weight: 400;
                        font-size: 15px;
                        word-wrap: 'break-word';
                        vertical-align: middle;
                        white-space: nowrap;
                        height: 50px;
                        max-height: 50px;

                        span {
                            font-size: 13px;
                            color: rgb(255, 255, 255, 1);
                        }
                    }


                    .favorite {
                        width: 3%;
                        text-align: center;
                    }

                    .alarm {
                        width: 1.5%;
                        text-align: left;
                    }

                    .vesselName {
                        width: 16.5%;
                        text-align: left;
                    }

                    .latestUpdate {
                        width: 7.75%;
                        text-align: left;
                    }

                    .padding1 {
                        width: 1%;
                    }

                    .CPCurve {
                        width: 10%;
                        text-align: center;
                    }

                    .padding2 {
                        width: 3%;
                    }

                    .Last4Weeks {
                        width: 7%;
                        text-align: center;
                    }

                    .ThisYear {
                        width: 7.5%;
                        text-align: center;
                    }

                    .LastYear {
                        width: 6%;
                        text-align: center;
                    }

                    /* .padding3 {
                        width: 0.75%;
                    } */

                    .eua-year-to-date {
                        width: 7%;    
                    }
    
                    .eua-end-of-year {
                        width: 7%;    
                    }
    
                    .padding4 {
                        width: 2%;
                    }
    
                    .cb-year-to-date {
                        width: 7%;    
                    }
    
                    .cb-end-of-year {
                        width: 7%;
                    }
    
                    .padding5 {
                        width: 5.75%;
                    }
                }
            }
        }
    }
}

/* 並び替え */
.common-sort-container {
    display: inline-block;
    padding-left: 4px;

    .common-sort-icon {
        /* display: flex; */
        /* align-content: baseline; */

        height: 25px;

        .common-sort-icon-ascending {
            width: 0;
            height: 0;
            margin-left: 2px;
            margin-top: 8px;
            border-style: solid;
            border-width: 0 5px 6px 5px;
            border-color: transparent transparent rgba(255, 255, 255, 0.5) transparent;
            cursor: pointer;

            &:hover {
                border-color: transparent transparent white transparent;
            }
        }

        .common-sort-icon-descending {
            width: 0;
            height: 0;
            margin-left: 2px;
            margin-top: 5px;
            border-style: solid;
            border-width: 6px 5px 0 5px;
            border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
            cursor: pointer;

            &:hover {
                border-color: white transparent transparent transparent;
            }
        }

        .sort-active-ascending {
            width: 0;
            height: 0;
            margin-left: 2px;
            margin-top: 8px;
            border-style: solid;
            border-width: 0 5px 6px 5px;
            border-color: transparent transparent white transparent;
            cursor: pointer;
        }

        .sort-active-descending {
            width: 0;
            height: 0;
            margin-left: 2px;
            margin-top: 5px;
            border-style: solid;
            border-width: 6px 5px 0 5px;
            border-color: white transparent transparent transparent;
            cursor: pointer;
        }
    }
}

/* アラーム赤のぼかし */
.alarm-blur {
    box-shadow: 2px 2px 25px red;
}

.vessel-list-header-left{
    display: flex;
}
.vessel-list-header-left > div:nth-child(1) {
    margin-top: 8px;
}

.vessel-list-header-center{
    display: flex;
    justify-content: center;
}
.vessel-list-header-center > div:nth-child(1) {
    margin-top: 8px;
}
.vessel-list-header-right{
    display: flex;
    justify-content: end;
}
.vessel-list-header-right > div:nth-child(1) {
    margin-top: 8px;
}
