.select-date-panel {
  .MuiPaper-root {
      width: 960px;
      max-width: 100vh;
      height: 540px;
      overflow-x: hidden; 
  }

  /* // カレンダー上部 */
  .MuiPickersLayout-toolbar {
      display: none;
  }

  /* // カレンダーの背景 */
  .MuiPickersLayout-root {
      background-color: initial;
  }


  /* // 月, 矢印ボタン */
  .MuiPickersArrowSwitcher-root {
      color: white;

      button {
          color: white;
      }
  }

  /* // 曜日 */
  .MuiDayCalendar-header span {
      color: white;
  }

  /* // 日 */
  .MuiDateRangePickerDay-root div button {
      color: white;

      &:hover {
          background-color: #9da0ac;
      }
  }


  /* .left-select {
      box-sizing: border-box;
      padding-top: 70px;
      padding-left: 42px;
      float: left;
      width: 0px;
      height: 100%;

      .date-box {
          position: relative;
          border: 2px solid rgba(255, 255, 255, 0.7);
          background-color: rgba(255, 255, 255, 0.25);
          border-radius: 20px;
          padding: 0 18px;
          width: 150px;
          height: 40px;
          line-height: 40px;
          margin: 17px auto;
          outline: unset;
          cursor: pointer;

          .date-icon {
              margin-left: -4px;
              margin-bottom: -4px;
              margin-right: 0px;
              width: 20px;
              height: 20px;
          }

          &:hover {
              background: rgba(255, 255, 255, 0.5) !important;
          }
      }
  } */

  .select-ranges {
      float: left;
      width: calc(100%);
      height: 100%;
      display: flex;

      .calendar-right {
          width: 237px;
          height: 300px;
          margin-left: 40px;
          margin-top: 40px;
          position: relative;
      }

      .calendar-left {
          width: 237px;
          height: 300px;
          /* margin-left: 67.9px; */
          margin-top: 40px;
          position: relative;
      }

      .right-div,
      .left-div {
          width: 220px;
          height: 220px;
          border: 1px dotted rgba(255, 255, 255, 0.5);
          position: absolute;
          top: 30px;
          left: 0px;
      }

      .interval-div {
          border: 1px dotted rgba(255, 255, 255, 0.5);
          position: absolute;
          width: 45px;
          top: 230px;
          left: 250px;
      }

      .calendar-table {
          padding: 0 !important;
          border-spacing: 0;

          thead {
              th {
                  color: white !important;
                  font-family: 'Roboto Bold', 'Meiryo', sans-serif;
                  font-weight: bold;
                  font-size: 16px;
              }

              .prev,
              .next {
                  cursor: pointer;
                  height: 32px;
                  width: 32px;
                  padding: 0;
                  opacity: 0.5;

                  &:hover {
                      opacity: 0.8;
                  }
              }

              .prev {
                  transform: rotate(90deg);
              }

              .next {
                  transform: rotate(270deg);
              }

              .month {
                  min-width: 32px;
                  height: 32px;
                  font-size: 24px;
                  font-weight: bold;
                  font-family: 'Roboto Bold', 'Meiryo', sans-serif;
                  padding: 0;
                  white-space: nowrap;
                  text-align: center;
                  cursor: pointer;
              }
          }

          tbody {
              .day {
                  td {
                      font-size: 14px;
                      font-family: 'Roboto Regular';
                      height: 32px !important;
                      width: 31px;
                      min-width: 31px;
                      text-align: center;
                      white-space: nowrap;
                      margin: .25em 0;
                      opacity: 0.8;
                      cursor: pointer;

                      /* &:hover {
                          border-radius: 50%;
                          background-color: #1396a3;
                      } */

                      span {

                          input {
                              border: none;
                              outline: none;
                              background: none;
                              width: 100%;
                              height: 100%;
                              text-align: center;
                              color: white;
                              cursor: pointer;

                          }

                          &:hover {
                              background: #00ffff;
                              color: #40475d;
                              border-radius: 50%;
                              display: inline-block;
                              height: 30px;
                              line-height: 30px;
                              width: 100%;
                          }
                      }
                  }

                  .is-diabled {
                      input {
                          color: #808aa3;
                      }
                  }

                  .is-checked {
                      background: #00ffff;
                      border-radius: 50%;
                      height: 100%;
                      width: 100%;
                      display: inline-block;

                      input {
                          color: #40475d !important;
                      }
                  }

                  .is-start-range-checked {
                      color: #40475d;
                      background-color: #1396a3;
                      border-bottom-left-radius: 50%;
                      border-top-left-radius: 50%;

                  }

                  .is-end-range-checked {
                      background-color: #1396a3;
                      border-top-right-radius: 50%;
                      border-bottom-right-radius: 50%;

                  }

                  .is-range-checked {
                      color: #40475d;
                      background-color: #1396a3;
                      width: 31px;
                  }

              }

          }

      }


      .change-hidden {
          display: none;
      }
  }

  .arr {
      margin-left: 8px;
      width: 9px !important;
      height: 4px !important;
      float: right;
      margin-top: 20px;
  }

}

.course-icon {
  height: 24px;
  width: 24px;
}
.date-icon {
height: 20px;
width: 20px;
}
.oil-icon {
height: 20px;
width: 20px;
}
.engine-icon {
height: 22px;
width: 22px;
}
.ship-icon {
height: 28px;
width: 28px;
}
.mainContainer {
    display: flex;
  }
  .side {
    width: 641px;
  }
  .mainChart {
    flex: 1;
    width: 300px;  /*selectバー開閉時の微調整のため*/
  }
.svg-icon g path:nth-child(1){
    &:hover {
        fill: white;
    }
}
.plusButton{
    height: 18px;
    width: 18px;
}