.cii-analysis-main-aera {
    width: 99.5%;
    min-width: 1400px;
    height: calc(100% - 35px);
    /* background-color: antiquewhite;
    opacity: 0.1; */
    position: relative;
    left: 0.25%;
    overflow-x: auto;
}

