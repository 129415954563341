.total-area {
    height: 197px;
    background: linear-gradient(
        180deg,
        rgba(46, 46, 48, 0.30) 0%, 
        rgba(65, 66, 68, 0.30) 20%
    );
}

.total-area-section {
    margin-left: 9%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700;
}

.total-data-titole1 {
    width: 49%;
    margin-Left: 13%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700;
}

.total-data-titole2 {
    width: 51%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700
}

.total-data-titole-cb {
    width: 55%;
    margin-Left: 11%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700;
}

.total-data-titole-cb-cost {
    width: 45%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700
}

.total-data-value {
    width: 49%;
    margin-left: 13%;
    margin-top: 5px;
    color: white;
    font-size: 25px;
    font-family: 'Roboto'; 
}

.total-data-cost {
    width: 51%;
    margin-top: 5px;
    color: white;
    font-size: 25px;
    font-family: 'Roboto'
}

.total-data-value-cb {
    width: 55%;
    margin-left: 11%;
    margin-top: 5px;
    color: white;
    font-size: 25px;
    font-family: 'Roboto'; 
}

.total-data-cost-cb {
    width: 45%;
    margin-top: 5px;
    color: white;
    font-size: 25px;
    font-family: 'Roboto'
}

.total-area-section-right {
    margin-left: 5%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700;
}

.total-area-section-right-cb {
    margin-left: 3%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700;
}

.total-data-titole1-right {
    width: 48%;
    margin-Left: 8%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700;
}

.total-data-titole2-right {
    width: 52%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700
}

.total-data-titole-cb-right {
    width: 52%;
    margin-Left: 5%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700;
}

.total-data-titole-cb-cost-right {
    width: 48%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700
}

.total-data-value-right {
    width: 48%;
    margin-left: 8%;
    margin-top: 5px;
    color: white;
    font-size: 25px;
    font-family: 'Roboto'; 
}

.total-data-cost-right {
    width: 52%;
    margin-top: 5px;
    color: white;
    font-size: 25px;
    font-family: 'Roboto'
}

.total-data-value-cb-right {
    width: 52%;
    margin-left: 5%;
    margin-top: 5px;
    color: white;
    font-size: 25px;
    font-family: 'Roboto'; 
}

.total-data-cost-cb-right {
    width: 48%;
    margin-top: 5px;
    color: white;
    font-size: 25px;
    font-family: 'Roboto'
}

.unit-eur {
    font-size: 24px;
    color: rgb(255, 255, 255, 0.5);
}

/* 区切り棒 */
.separate-bar {
    height: 100%;
    width: 100%;

    .bar {
        height: 100px;
        width: 1px;
        background: linear-gradient(
            to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    }
}

.list-area {
    height: calc(100% - 295px);
    background: linear-gradient(
        180deg,
        rgba(46, 46, 48, 0.30) 0%, 
        rgba(65, 66, 68, 0.30) 20%
    );
}

.eua-cb-list-area-wrapper {
    width: 97%;
    height: calc(100% - 130px);
    margin: 0 auto;
    white-space: nowrap;
    overflow-y: hidden;

    .eua-cb-vessel-list-table {
        font-size: 14px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        /* border-collapse: collapse; */
        table-layout: fixed;
        display: block;

        thead tr {
            width: 100%;
            /* height: 100%; */
            align-items: center;
            display: table;
            border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
        }
        tbody tr {
            width: 100%;
            height: 50px;
            align-items: center;
            display: table;
        }

        thead {
            display: table;
            width: 100%;

            th {
                /* border: 1px #CBCBCB solid; */
                font-family: Roboto, "Helvetica Neue", sans-serif;
                font-weight: 700;
                position: sticky;

                span {
                    font-size: 14px;
                    color: rgb(255, 255, 255, 0.5);
                }
            }

            .padding1 {
                width: 1.2%;
                /* text-align: left; */
            }

            .vesselName {
                width: 11.5%;
                /* text-align: left; */
            }

            .operator {
                width: 5%;
                /* text-align: left; */
            }

            .year-to-date-distance {
                width: 6.8%;
                /* text-align: left; */
            }

            .year-to-date-foc {
                width: 6%;
                /* text-align: right; */
            }

            .year-to-date-eua {
                width: 5%;
                /* text-align: left; */
                padding-left: 20px;
            }

            .year-to-date-eua-cost {
                width: 8%;
                /* text-align: right; */
            }

            .year-to-date-cb {
                width: 4.5%;
                /* text-align: left; */
                padding-left: 20px;
            }

            .year-to-date-cb-cost {
                width: 7.5%;
                /* text-align: right; */

            }

            .padding2 {
                width: 1.5%;
            }

            .end-of-year-distance {
                width: 7.5%;
                /* text-align: left; */
            }

            .end-of-year-foc {
                width: 5.75%;
                /* text-align: right; */
            }

            .end-of-year-eua {
                width: 5.5%;
                /* text-align: left; */
                padding-left: 20px;
            }

            .end-of-year-eua-cost {
                width: 7.5%;
                /* text-align: right; */
            }

            .end-of-year-cb {
                width: 5.25%;
                /* text-align: left; */
            }

            .end-of-year-cb-cost {
                width: 7%;
                /* text-align: right; */
                padding-right: 10px;

            }

            .padding3 {
                width: 1%;
            }
 

        }

        tbody {
            display: block;
            overflow-y: auto;
            height: 90%;
            width: 100%;

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.5);
            }

            &::-webkit-scrollbar-thumb {
                background-color: #fff;
                border-radius: 10px;
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-corner {
                display: none;
            }

            tr {

                width: 100%;

                &:nth-of-type(odd) {
                    background-color: rgba(255, 255, 255, 0.05);
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.15);
                }

                td {
                    padding-bottom: 0px;
                    /* border: 1px #CBCBCB solid; */
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 15px;
                    word-wrap: 'break-word';
                    vertical-align: middle;
                    white-space: nowrap;
                    height: 50px;
                    max-height: 50px;

                    span {
                        width: 100%;
                        text-align: right;
                        font-size: 15px;
                        color: rgb(255, 255, 255, 1);
                    }
                }


                .padding1 {
                    width: 1.2%;
                }
    
                .vesselName {
                    width: 11.5%;
                }
    
                .operator {
                    width: 5%;
                }
    
                .year-to-date-distance {
                    width: 6.8%;
                    text-align: right;
                }
    
                .year-to-date-foc {
                    width: 6%;
                    text-align: right;
                }
    
                .year-to-date-eua {
                    width: 5%;
                    padding-left: 20px;
                }
    
                .year-to-date-eua-cost {
                    width: 8%;
                }
    
                .year-to-date-cb {
                    width: 4.5%;
                }
    
                .year-to-date-cb-cost {
                    width: 7.5%;
    
                }
    
                .padding2 {
                    width: 1.5%;
                }
    
                .end-of-year-distance {
                    width: 7.5%;
                    text-align: right;
                }
    
                .end-of-year-foc {
                    width: 5.75%;
                    text-align: right;
                }
    
                .end-of-year-eua {
                    width: 5.5%;
                    padding-left: 20px;
                }
    
                .end-of-year-eua-cost {
                    width: 7.5%;
                }
    
                .end-of-year-cb {
                    width: 5.25%;
                }
    
                .end-of-year-cb-cost {
                    width: 7%;
                    padding-right: 10px;
    
                }
    
                .padding3 {
                    width: 1%;
                }
            }
        }
    }
}

/* 横線 */
.horizontal-bar-MRV {
    height: 100%;
    width: 35%;
    
    .bar {
        margin-top: 15px;
        height: 1px;
        width: 100%;
        background: linear-gradient(
            to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    }
}

/* 横線 */
.horizontal-bar-EUA {
    height: 100%;
    width: 35%;
    
    .bar {
        margin-top: 15px;
        height: 1px;
        width: 100%;
        background: linear-gradient(
            to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    }
}

/* 横線 */
.horizontal-bar-FuelEU {
    height: 100%;
    width: 20%;
    
    .bar {
        margin-top: 15px;
        height: 1px;
        width: 100%;
        background: linear-gradient(
            to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    }
}

.sub-header {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: rgb(255, 255, 255, 0.5);
}

/* 並び替え */
.eu-list-common-sort-container {
    display: inline-block;
    padding-left: 4px;

    .eu-list-common-sort-icon {
        /* display: flex; */
        /* align-content: baseline; */

        height: 25px;

        .eu-list-common-sort-icon-ascending {
            width: 0;
            height: 0;
            margin-left: 2px;
            margin-top: 8px;
            border-style: solid;
            border-width: 0 5px 6px 5px;
            border-color: transparent transparent rgba(255, 255, 255, 0.5) transparent;
            cursor: pointer;

            &:hover {
                border-color: transparent transparent white transparent;
            }
        }

        .eu-list-common-sort-icon-descending {
            width: 0;
            height: 0;
            margin-left: 2px;
            margin-top: 5px;
            border-style: solid;
            border-width: 6px 5px 0 5px;
            border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
            cursor: pointer;

            &:hover {
                border-color: white transparent transparent transparent;
            }
        }

        .eu-list-sort-active-ascending {
            width: 0;
            height: 0;
            margin-left: 2px;
            margin-top: 8px;
            border-style: solid;
            border-width: 0 5px 6px 5px;
            border-color: transparent transparent white transparent;
            cursor: pointer;
        }

        .eu-list-sort-active-descending {
            width: 0;
            height: 0;
            margin-left: 2px;
            margin-top: 5px;
            border-style: solid;
            border-width: 6px 5px 0 5px;
            border-color: white transparent transparent transparent;
            cursor: pointer;
        }
    }
}

.eu-list-header-left{
    display: flex;
}
.eu-list-header-left > div:nth-child(1) {
    margin-top: 8px;
}
.eu-list-header-center{
    display: flex;
    justify-content: center;
}
.eu-list-header-center > div:nth-child(1) {
    margin-top: 8px;
}
.eu-list-header-right{
    display: flex;
    justify-content: end;
}
.eu-list-header-right > div:nth-child(1) {
    margin-top: 8px;
}
