table {
    border-collapse:collapse;

    tbody {
        td {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 4px;
        }
    }
}
.routeWetherCond {
    position: relative;
    height: 495px;
    display: flex;
    flex-direction: row; 
}
.map {
    flex-grow: 1;
}
.pointInformation{
    width: 642px;
    flex-shrink: 0;
}
.timeSeries{
    position: "relative";
    height: "270px";
    display: flex;
    flex-direction: row; 
}
.graph {
    flex-grow: 1;
    width: 200px;
}
.legend{
    width: 255px;
    flex-shrink: 0;
}