.me-graph-wrapper {
  /* display: flex; */
  flex-direction: column;    /* 子要素を縦に並べる */
  justify-content: center;   /* 垂直方向に中央揃え */
  align-items: center;       /* 水平方向に中央揃え */
  text-align: center;
  height: 100%;              /* 高さを100%に設定 */
  /* background-color: aquamarine; */
}

.me-graph-title {
  font-size: 12px;
  color: rgba(218, 218, 218, 0.7);
  /* background-color: aqua; */
}

.me-graph-graph {
  position: relative;
  margin-top: 0px;
  height: 75px;
  width: 75px;
  /* background-color: aqua; */
}

.me-graph-value, .me-graph-unit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  text-align: center;
  pointer-events: none;
  color: #fff;
}

.me-graph-value {
  font-family: 'Roboto';
  font-weight: 100;
  font-size: 13px;
}

.me-graph-unit {
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 12px;
  color: rgba(218, 218, 218, 0.7);
  transform: translate(-50%, 10%);
}
