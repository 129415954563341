.eu-support-tool-total-area {
    height: 197px;
    background: linear-gradient(
       180deg,
       rgba(46, 46, 48, 0.30) 0%, 
       rgba(65, 66, 68, 0.30) 20%
    );
}

.eu-support-tool-total-area-section {
    margin-left: 7%;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700;
}

.eu-support-tool-total-data-titole1 {
    width: 45%;
    margin-Left: 10%;
    margin-top: 5px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700;
}

.eu-support-tool-total-data-titole2 {
    width: 55%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700
}

.eu-support-tool-total-data-titole-cb {
    width: 50%;
    margin-Left: 10%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700;
}

.eu-support-tool-total-data-titole-cb-cost {
    width: 50%;
    margin-top: 15px;
    color: rgb(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 700
}

.eu-support-tool-total-data-value {
    width: 45%;
    margin-left: 10%;
    margin-top: 5px;
    color: white;
    font-size: 25px;
    font-family: 'Roboto'; 
}

.eu-support-tool-total-data-cost {
    width: 55%;
    margin-top: 5px;
    color: white;
    font-size: 25px;
    font-family: 'Roboto'
}

.eu-support-tool-total-data-value-cb {
    width: 50%;
    margin-left: 10%;
    margin-top: 5px;
    color: white;
    font-size: 25px;
    font-family: 'Roboto'; 
}

.eu-support-tool-total-data-cost-cb {
    width: 50%;
    margin-top: 5px;
    color: white;
    font-size: 25px;
    font-family: 'Roboto'
}

.unit-eur {
    font-size: 24px;
    color: rgb(255, 255, 255, 0.5);
}

/* 区切り棒 */
.eu-support-tool-total-area-separate-bar {
    height: 100%;
    width: 100%;

    .bar {
        height: 50px;
        width: 1px;
        background: linear-gradient(
            to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    }
}

.eu-support-tool-list-area {
    height: calc(100% - 295px);
    background: linear-gradient(
        180deg,
        rgba(46, 46, 48, 0.30) 0%, 
        rgba(65, 66, 68, 0.30) 20%
    );
}

/* 横線 */
.horizontal-bar-MRV {
    height: 100%;
    width: 35%;
    
    .bar {
        margin-top: 15px;
        height: 1px;
        width: 100%;
        background: linear-gradient(
            to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    }
}

/* 横線 */
.horizontal-bar-EUA {
    height: 100%;
    width: 35%;
    
    .bar {
        margin-top: 15px;
        height: 1px;
        width: 100%;
        background: linear-gradient(
            to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    }
}

/* 横線 */
.horizontal-bar-FuelEU {
    height: 100%;
    width: 20%;
    
    .bar {
        margin-top: 15px;
        height: 1px;
        width: 100%;
        background: linear-gradient(
            to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    }
}

/* .sub-header {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 700;
    font-size: 13px;
    color: rgb(255, 255, 255, 0.5);
} */
 
/*----------------------------------------
    Pooling Group リスト
------------------------------------------*/
/* 全体-スクロール */
.fueleu-support-tool-list-scroll {
    height: calc(100% - 40px);
    width: 100%;
    overflow-y: auto;
}
/* スクロールバー関連 */
.fueleu-support-tool-list-scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.fueleu-support-tool-list-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.5);
}
.fueleu-support-tool-list-scroll::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
}
.fueleu-support-tool-list-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
.fueleu-support-tool-list-scroll::-webkit-scrollbar-corner {
    display: none;
}

/* アコーディオン一つ分の大本 */
.fueleu-support-tool-list {
    width: 100%;
    background-color: rgba(69, 72, 80, 0.25);
}

/* アコーディオン サマリ部 */
.fueleu-support-tool-list-summary {
    height: 50px;
    display: flex;
    color: white;
    box-shadow: 0px 3px 9px -2px rgba(0, 0, 0, 0.50);
}

.fueleu-support-tool-list-summary > div {
    margin: auto 0;
}

/* サマリ部-ホバー */
.fueleu-support-tool-list-summary:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

/* アコーディオンアイコン */
.fueleu-support-tool-list-summary > div:nth-child(1) {
    width: 2.6%;
}

.fueleu-support-tool-list-summary > div:nth-child(1) > img {
    height: 10px;
    width: 6px;
    display: block;
    margin: auto;
    transition: transform 0.5s;
}

.pooling-group-accordion > div > div:nth-child(1) > img {
    transform: rotate(90deg);
    transition: transform 0.5s;
}

/* グループ名 */
.fueleu-support-tool-summary-group-name {
    width: 13%;
    font-size: 15px;
    font-weight: bold;
}

/* Totalコンプライアンスバランス */
.fueleu-support-tool-summary-total-cb {
    width: 15.3%;
    font-size: 18px;
}

/* Totalコンプライアンスバランス コスト */
.fueleu-support-tool-summary-total-cb-cost {
    width: 500px;
}
.fueleu-support-tool-summary-total-cb-cost > span:nth-child(1) {
    /* 金額 */
    font-size: 18px;
}
.fueleu-support-tool-summary-total-cb-cost > span:nth-child(2) {
    /* 単位 */
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 5px;
}

/*----------------------------------------
    Pooling Group 詳細リスト
------------------------------------------*/
.fueleu-support-tool-list-detail {
    overflow: hidden;
    max-height: 0;
    background: rgba(70, 70, 70, 0.08);
    transition: max-height 0.2s;
}

.pooling-group-accordion > .fueleu-support-tool-list-detail {
    width: 100%;
    height: auto;
    max-height: 100vh;
    background: rgba(70, 70, 70, 0.08);
    transition: max-height 1.5s;
}

/* Pooling Group List テーブル */
.pooling-group-list-table-wrapper {

    width: calc(100% - 63px);
    height: calc(100% - 47px);
    
    margin-left: 35px;
    white-space: nowrap;
    overflow-y: hidden;

    .pooling-group-list-table {
    
        font-size: 14px;
        width: 100%;
        height: 100%;
        table-layout: fixed;
        display: block;

        thead tr {

            width: 100%;
            /* height: 100%; */
            align-items: center;
            display: table;
            border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
        }
        tbody tr {
            width: 100%;
            height: 50px;
            align-items: center;
            display: table;
        }

        thead {
            display: table;
            width: 100%;

            th {
                /* border: 1px #CBCBCB solid; */
                font-family: Roboto, "Helvetica Neue", sans-serif;
                font-weight: bold;
                position: sticky;

                span {
                    font-size: 14px;
                    color: rgb(255, 255, 255, 0.5);
                }
            }

            .pooling-group-list-vesselName {
                width: 13.7%;
                padding-left: 22px;
            }

            .pooling-group-list-operator {
                width: 5.7%;
            }

            .pooling-group-list-distance {
                width: 6.9%;
            }

            .pooling-group-list-foc {
                width: 7.4%;
            }

            .pooling-group-list-year-to-date {
                width: 8.7%;
            }

            .pooling-group-list-last-year {
                width: 6.7%;
            }

            .pooling-group-list-borrowing-limit {
                width: 9.1%;
            }

            .pooling-group-list-borrowing {
                width: 9.2%;
            }

            .pooling-group-list-banking {
                width: 7.8%;
            }

            .pooling-group-list-total {
                width: 7.4%;
            }

            .pooling-group-list-penalty-factor {
                width: 8.9%;
            }

            .pooling-group-list-cost-eur {
                width: 8.5%;
                padding-right: 36px;
            }
        }

        tbody {
            display: block;
            overflow-y: auto;
            /* height: 100%; */
            max-height: 197px;
            width: 100%;

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.5);
            }

            &::-webkit-scrollbar-thumb {
                background-color: #fff;
                border-radius: 10px;
            }

            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }

            &::-webkit-scrollbar-corner {
                display: none;
            }

            tr {

                width: 100%;

                &:nth-of-type(odd) {
                    background-color: rgba(69, 72, 80, 0.25);
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.15);
                }

                td {
                    padding-bottom: 0px;
                    /* border: 1px #CBCBCB solid; */
                    font-family: Roboto;
                    font-weight: 400;
                    /* font-size: 15px; */
                    word-wrap: 'break-word';
                    vertical-align: middle;
                    white-space: nowrap;
                    height: 50px;
                    max-height: 50px;

                    span {
                        font-size: 15px;
                        color: rgb(255, 255, 255, 1);
                    }
                }

                .pooling-group-list-vesselName {
                    width: 13.7%;
                    padding-left: 22px;
                    text-align: left;
                }
        
                .pooling-group-list-operator {
                    width: 5.7%;
                    text-align: left;
                }
        
                .pooling-group-list-distance {
                    width: 6.9%;
                    text-align: right;
                }
        
                .pooling-group-list-foc {
                    width: 7.4%;
                    text-align: right;
                }
        
                .pooling-group-list-year-to-date {
                    width: 8.7%;
                    text-align: right;
                }
        
                .pooling-group-list-last-year {
                    width: 6.7%;
                    text-align: right;
                }
        
                .pooling-group-list-borrowing-limit {
                    width: 9.1%;
                    text-align: right;
                }
        
                .pooling-group-list-borrowing {
                    width: 9.2%;
                    text-align: right;
                }
        
                .pooling-group-list-banking {
                    width: 7.8%;
                    text-align: right;
                }
        
                .pooling-group-list-total {
                    width: 7.4%;
                    text-align: right;
                }
        
                .pooling-group-list-penalty-factor {
                    width: 8.9%;
                    text-align: right;
                }
        
                .pooling-group-list-cost-eur {
                    width: 8.5%;
                    padding-right: 36px;
                    text-align: right;
                }
            }
        }
    }
}



